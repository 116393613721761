import { api } from 'app/services';

export const ordersApi = api.injectEndpoints({
    endpoints: (builder) => ({

        orders: builder.query({
            query: () => {
                return {
                    url: `/api/order/get-orders`,
                    method: 'GET',
                }
            },
        }),
        chatInboxUsers: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/chat/order-inbox/${id}`,
                    method: 'GET',
                }
            },
        }),
        revisions: builder.query<[], void>({
            query: () => {
                return {
                    url: `/api/revision`,
                }
            },
            providesTags: ['Revision']
        }),
        sendMessage: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/chat`,
                    method: 'POST',
                    body
                }
            },
            invalidatesTags: ['Inbox']
        }),
        submitForReview: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/order/submit-for-review`,
                    method: 'POST',
                    body
                }
            },
            invalidatesTags: ['SubmitReview', 'Revision']
        }),
        messages: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/chat`,
                    method: 'PATCH',
                    body
                }
            },
        }),
        createVersion: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/revision/create-version/${id}`,
                    method: 'PATCH',
                }
            },
            invalidatesTags: [
                'CreateVersion',
                'Revision',
            ]
        }),
        rejectOrder: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/order/reject-order`,
                    method: 'PATCH',
                    body
                }
            },
            invalidatesTags: ['AcceptOrReject']
        }),
        acceptOrder: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/order/accept-order/${id}`,
                    method: 'PATCH',
                }
            },
            invalidatesTags: [
                'AcceptOrReject',
                'Dashboard'
            ]
        }),
        orderDetails: builder.query({
            query: (id) => {
                return {
                    url: `/api/order/order-details/${id}`,
                    method: 'GET',
                }
            }, //! this not how we suppose to use tags
            providesTags: ['AcceptOrReject', 'SubmitReview', 'CreateVersion']
        }),
        orderTransactionDetails: builder.query({
            query: (orderId) => {
                return {
                    url: `/api/payment/order-transaction-details/${orderId}`,
                    method: 'GET',
                }
            },
        }),
    }),
})

export const {
    useOrdersQuery,
    useChatInboxUsersMutation,
    useSendMessageMutation,
    useMessagesMutation,
    useOrderDetailsQuery,
    useAcceptOrderMutation,
    useRejectOrderMutation,
    useSubmitForReviewMutation,
    useCreateVersionMutation,
    useRevisionsQuery,
    useOrderTransactionDetailsQuery,
} = ordersApi