const baseUrl = process.env.NEXT_PUBLIC_APP_BASE_URL
const AppUrl = process.env.NEXT_PUBLIC_APP_URL
const baseUrlLocal = process.env.NEXT_PUBLIC_APP_BASE_URL_LOCAL
const artistHelpBaseUrl = process.env.NEXT_PUBLIC_APP_ARTIST_HELP_BASE_URL

export {
    AppUrl,
    baseUrl,
    baseUrlLocal,
    artistHelpBaseUrl,
}
