import { api } from 'app/services'

export const sharedApi = api.injectEndpoints({
    endpoints: (builder) => ({
        
        countries: builder.query<[], void>({
            query: () => {
                return {
                    url: `/api/country`,
                }
            },
        }),
       
        topics: builder.query<[], void>({
            query: () => {
                return {
                    url: `/api/topic`,
                }
            },
        }),
        
        updateArtistOpenForWork: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/user/open-for-work`,
                    method: 'PUT',
                    body
                }
            },
        }),

        addMedia: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/media`,
                    method: 'POST',
                    body
                }
            },
        }),
    }),
})

export const {
    useCountriesQuery,
    useUpdateArtistOpenForWorkMutation,
    useTopicsQuery,
    useAddMediaMutation,
} = sharedApi