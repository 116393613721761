import { api } from 'app/services'

export const profileApi = api.injectEndpoints({
    endpoints: (builder) => ({

        artistProfile: builder.query({
            query: (id) => {
                return {
                    url: `/api/user/artist-details/${id}`,
                    method: 'GET',
                }
            },
        }),
        
        updateArtistProfile: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/user/artist-details`,
                    method: 'PUT',
                    body
                }
            },
        }),

        songs: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/song`,
                    method: 'PATCH',
                    body
                }
            },
        }),

        deleteSong: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/song/${id}`,
                    method: 'DELETE',
                }
            },
        }),
    }),
})

export const {
    useArtistProfileQuery,
    useUpdateArtistProfileMutation,
    useSongsMutation,
    useDeleteSongMutation,
} = profileApi