// cookie utility functions

function getEffectiveTopLevelDomain() {
    var hostname = window.location.hostname;
    // Split the hostname into parts
    var parts = hostname.split('.');

    // Check for IP address or localhost
    if (parts.length === 4 && parts.every(part => Number.isInteger(Number(part)))) {
        // It's an IP address, return the full hostname
        return hostname;
    }
    if (hostname === "localhost" || parts.length === 1) {
        // Localhost or single-part hostname, return as is
        return hostname;
    }

    // Check if the last part is two letters (ccTLD) and the part before it is three letters (SLD)
    if (parts.length > 2 && parts[parts.length - 2].length <= 3 && parts[parts.length - 1].length === 2) {
        // Likely a second-level domain like '.co.uk', take the last three parts
        return parts.slice(-3).join('.');
    } else {
        // Otherwise, take the last two parts for the TLD
        // This does not accurately handle all SLDs but works for many cases
        return parts.slice(-2).join('.');
    }
}

export function clearStoredValue(name: string) {
    setCookie(name, '', -1)
    localStorage.removeItem(name);
}

export function setCookie(name: string, value: string, daysToExpire: number) {
    const date = new Date();
    date.setDate(date.getDate() + daysToExpire);
    const expires = "expires=" + date.toUTCString();
    document.cookie = `${name}=${value};${expires};path=/;domain=.${getEffectiveTopLevelDomain()}; secure; samesite=Lax`;
}

export const getCookie = (name: string): string | undefined => {
    const cookieValue = `; ${document.cookie}`;
    const parts = cookieValue.split(`; ${name}=`);
    return (parts.length === 2 ? parts.pop()?.split(';').shift() : undefined) || undefined;
};


export function setLocalStorageExpire(name: string, value: string, daysToExpire: number): void {
    const date = new Date();
    date.setDate(date.getDate() + daysToExpire);
    const item = {
        value: value,
        expires: date.toISOString(),
    };
    localStorage.setItem(name, JSON.stringify(item));
}

export function getLocalStorageExpire(name: string): string | undefined {
    try {
        const storedItem = localStorage.getItem(name);
        if (!storedItem) return undefined;

        const { value, expires } = JSON.parse(storedItem);

        const now = new Date();
        const expiryDate = new Date(expires);

        if (now < expiryDate) {
            return value;
        } else {
            localStorage.removeItem(name);
            return undefined;
        }

    } catch (e) { console.log(e) }

    return undefined;
}



export function setLocalStorage(name: string, value: string): void {
    localStorage.setItem(name, JSON.stringify(value));
}

export function getLocalStorage(name: string): string | undefined {
    try {
        const storedItem = localStorage.getItem(name);
        if (!storedItem) return undefined;


        const value = JSON.parse(storedItem);
        return value;

    } catch (e) { console.log(e) }

    return undefined;
}


// Utility function to get the param from the query string
export function getQueryParam(param: string): string | undefined {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param) || undefined;
}

/// save both functions

function storeValue(name: string, value: string, expireDays: number | undefined = undefined): void {
    // Set the value to expire after 30 days
    if (expireDays) {
        setCookie(name, value, expireDays);
        setLocalStorageExpire(name, value, expireDays);
    }
    else {
        setCookie(name, value, 365);
        setLocalStorage(name, value);
    }

}

function getStoredValueAndEnsureSaved(name: string, expireDays: number | undefined = undefined): string | undefined {
    // Attempt to get the value from a cookie
    const cookieValue = getCookie(name);

    // If not found in the cookie, check local storage
    let localStorageValue
    if (expireDays)
        localStorageValue = getLocalStorageExpire(name);
    else
        localStorageValue = getLocalStorage(name);


    // ensure value is stored the other way too, with cookie is priority and local storage is backup

    if (cookieValue && cookieValue !== localStorageValue) {
        if (expireDays)
            setLocalStorageExpire(name, cookieValue as string, expireDays);
        else
            setLocalStorage(name, cookieValue as string);
    }

    if (localStorageValue && !cookieValue) {
        if (expireDays)
            setCookie(name, localStorageValue as string, expireDays);
        else
            setCookie(name, localStorageValue as string, 365);
    }


    if (cookieValue) {
        return cookieValue;
    }
    if (localStorageValue) {
        return localStorageValue;
    }
    return undefined
}

export function storeGetValue(value: string | undefined, name: string, expireDays: number | undefined = undefined): string | undefined {
    if (value) {
        storeValue(name, value, expireDays);
    } else {
        value = getStoredValueAndEnsureSaved(name, expireDays);
    }
    return value;
}


