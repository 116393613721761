import { api } from 'app/services';

export const inboxApi = api.injectEndpoints({
    endpoints: (builder) => ({

        chatInbox: builder.query({
            query: () => {
                return {
                    url: `/api/chat/inbox`,
                    method: 'GET',
                }
            },
            providesTags: ['Inbox']
        }),

    }),
})

export const {
    useChatInboxQuery,
} = inboxApi