import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import product from './slices/product';
import authSlice from '~screens/auth/store/authSlice';
import sharedSlice from '~screens/shared/store/sharedSlice';
import ocassionsStepSlice from '~screens/occasions-step-1/store/ocassionsStepSlice';
import artistInvitationSlice from '~screens/artist-invitation/store/artistInvitationSlice';
import profileSlice from '~screens/profile/store/profileSlice';
import ordersSlice from '~screens/orders/store/ordersSlice';
import { api } from 'app/services';
import inboxSlice from '~screens/inbox/store/inboxSlice';

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [
    'auth',
    'artistInvitation'
  ],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const combinedReducer = combineReducers({
  shared: sharedSlice,
  auth: authSlice,
  ocasionStep: ocassionsStepSlice,
  artistInvitation: artistInvitationSlice,
  orders: ordersSlice,
  inbox: inboxSlice,
  profile: profileSlice,
  mail: mailReducer,
  product: product,

  [api.reducerPath]: api.reducer,
});

// const rootReducer = (state: any, action: any) => {
//   if (action.type === "auth/ChangeUser") {
//     state = undefined;
//   }
//   return combineReducers({
//     shared: sharedSlice,
//     auth: authSlice,
//     ocasionStep: ocassionsStepSlice,
//     artistInvitation: artistInvitationSlice,
//     orders: ordersSlice,
//     inbox: inboxSlice,
//     profile: profileSlice,
//     mail: mailReducer,
//     product: product,

//     [api.reducerPath]: api.reducer,
//   });
// }

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/ChangeUser") {
    state = undefined;
  }
  return combinedReducer(state, action)
}

export default rootReducer;
