import { createSlice } from '@reduxjs/toolkit';
import { profileApi } from '../services/profileApi';

const profileSlice = createSlice({
    name: 'auth',
    initialState: {
        artistProfileData: null,
        currentlyPlayingSong: null,
    },
    reducers: {
        ChangeCurrentlyPlayingSong: (state, action) => {
            state.currentlyPlayingSong = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                profileApi.endpoints.artistProfile.matchFulfilled,
                (state, { payload }) => {
                    state.artistProfileData = payload
                }
            )
    },
});

export const {
    ChangeCurrentlyPlayingSong
} = profileSlice.actions;

export default profileSlice.reducer;