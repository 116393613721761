import { CacheProvider, EmotionCache } from '@emotion/react'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'simplebar-react/dist/simplebar.min.css'
import { MotionLazyContainer } from '~components/animate'
import ProgressBar from '~components/progress-bar'
import { SettingsProvider, ThemeSettings } from '~components/settings'
import SnackbarProvider from '~components/snackbar'
import { AuthProvider } from '~config/auth/JwtContext'
import ThemeLocalization from '~config/locales'
import '~config/locales/i18n'
import { Provider, useSelector } from 'react-redux';
import { store } from 'app/store/store';
import ThemeProvider from '~config/theme'
import createEmotionCache from '~config/utils/createEmotionCache'
import '~styles/global.css'
import { Toaster } from 'react-hot-toast'

import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { trackIdentifyUser, trackVirtualPageVisit } from '../app/services/tagging'


const clientSideEmotionCache = createEmotionCache()

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

export function Pagesense() {
  const {
    user,
  } = useSelector((
    state: any
  ) => state.auth)

  useEffect(() => {
    const email = user?.userDto?.role?.name != 'Visitor' && user?.userDto?.email;
    if (email) {
      trackIdentifyUser(email)
    }
  }, [user?.userDto?.role?.name, user?.userDto?.email]);
  return null;
}
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
  Component: NextPageWithLayout
}

export default function MyApp(props: MyAppProps) {
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props

  const router = useRouter();

  useEffect(() => {
    trackVirtualPageVisit()
  }, [router.asPath])

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <Provider store={store} >
        <CacheProvider value={emotionCache}>
          <Head>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
            <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
            <meta name="emotion-insertion-point" content="" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.7.0/css/flag-icons.min.css" />
          </Head>

          <AuthProvider>
            <SettingsProvider>
              <MotionLazyContainer>
                <ThemeProvider>
                  <ThemeSettings>
                    <ThemeLocalization>
                      <SnackbarProvider>
                        <ProgressBar />
                        {getLayout(<Component {...pageProps} />)}
                      </SnackbarProvider>
                    </ThemeLocalization>
                  </ThemeSettings>
                  <Pagesense />
                </ThemeProvider>
              </MotionLazyContainer>
            </SettingsProvider>
          </AuthProvider>
        </CacheProvider>
      </Provider>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
    </>

  )
}
