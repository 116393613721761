import { createSlice } from '@reduxjs/toolkit';
import { artistInvitationApi } from '../services/artistInvitationApi';
import { authApi } from '../../auth/services/authApi';

const artistInvitationSlice = createSlice({
    name: 'artistInvitation',
    initialState: {
        musicTraits: null,
        step: 0,
        stepOneData: {},
        stepTwoData: {},
        stepThreeData: null,
        stepFourData: null,
        stepFiveData: {},
        stepSixData: {},
    },
    reducers: {
        changeStep: (state, action) => {
            state.step = action.payload
        },

        saveStepOneData: (state, action) => {
            state.stepOneData = action.payload
        },

        saveStepTwoData: (state, action) => {
            state.stepTwoData = action.payload
        },

        saveStepThreeData: (state, action) => {
            state.stepThreeData = action.payload
        },

        saveStepFourData: (state, action) => {
            state.stepFourData = action.payload
        },

        saveStepFiveData: (state, action) => {
            state.stepFiveData = action.payload
        },

        saveStepSixData: (state, action) => {
            state.stepSixData = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                artistInvitationApi.endpoints.musicTrait.matchFulfilled,
                (state, { payload }) => {
                    state.musicTraits = payload
                }
            ),
            builder.
                addMatcher(
                    authApi.endpoints.login.matchFulfilled,
                    (state, { payload }) => {

                        const {
                            firstName,
                            lastName,
                        } = payload?.userDto || ""

                        state.stepOneData = {
                            ...state.stepOneData,
                            firstName,
                            lastName,
                        }
                    }
                )
    },
});

export const {
    changeStep,
    saveStepOneData,
    saveStepTwoData,
    saveStepThreeData,
    saveStepFourData,
    saveStepFiveData,
    saveStepSixData,
} = artistInvitationSlice.actions;

export default artistInvitationSlice.reducer;