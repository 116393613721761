import { createSlice } from '@reduxjs/toolkit';
import { ordersApi } from '../services/ordersApi';

const ordersSlice:any = createSlice({
    name: 'artists',
    initialState: {
        orders: [],
        chatInboxUsers: [],
        messages: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                ordersApi.endpoints.orders.matchFulfilled,
                (state:any, { payload }) => {
                    state.orders = payload
                }
            )
            .addMatcher(
                ordersApi.endpoints.chatInboxUsers.matchFulfilled,
                (state:any, { payload }) => {
                    state.chatInboxUsers = payload
                }
            )
            .addMatcher(
                ordersApi.endpoints.messages.matchFulfilled,
                (state:any, { payload }) => {
                    state.messages = payload
                }
            )
    },
});

export const {
} = ordersSlice.actions;

export default ordersSlice.reducer;