import {
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react'
import {
    baseUrl,
    baseUrlLocal
} from 'app/config/baseUrl'

export const api
    = createApi({
        tagTypes: [
            'Inbox',
            'Revision',
            'Dashboard',
            'SubmitReview',
            'CreateVersion',
            'AcceptOrReject',
        ],
        baseQuery:
            fetchBaseQuery({
                baseUrl:
                    process.env.NODE_ENV
                        === 'production' ?
                        baseUrl
                        :
                        baseUrlLocal,
                prepareHeaders: (
                    headers,
                    { getState }: any
                ) => {

                    const token
                        = getState()
                            .auth
                            .user
                            ?.access_token

                    if (token)
                        headers
                            .set(
                                'authorization',
                                `Bearer ${token}`
                            )

                    return headers
                },
            }),
        endpoints: () => ({}),
    })