function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const WELCOME_TO_SONGOVEN ='/WelcomeToSongoven'

export const PATH_AUTH = {
  
  login: '/login',
  resetPassword: '/reset-password',
};


export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboard: path(ROOTS_DASHBOARD, '/'),
  welcomeToSonoven: path(ROOTS_DASHBOARD, '/welcomeToSonoven'),
  inbox: path(ROOTS_DASHBOARD, '/inbox'),
  profile: path(ROOTS_DASHBOARD, '/profile'),
  orders: path(ROOTS_DASHBOARD, '/orders'),
  revisions: path(ROOTS_DASHBOARD, '/revisions'),
  referralsProgram: path(ROOTS_DASHBOARD, '/referrals-program'),
};
