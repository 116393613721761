import { createSlice } from '@reduxjs/toolkit';
import { ocassionsStepApi } from '../services/ocassionsStepApi';

const ocassionsStepSlice = createSlice({
    name: 'ocassionsStep',
    initialState: {
        musicTraits: null,
        step: 0,
        stepOneData: {},
    },
    reducers: {
        changeStep: (state, action) => {
            state.step = action.payload
        },

        saveStepOneData: (state, action) => {
            state.stepOneData = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                ocassionsStepApi.endpoints.musicTrait.matchFulfilled,
                (state, { payload }) => {
                    state.musicTraits = payload
                }
            )
    },
});

export const {
    changeStep,
    saveStepOneData,
} = ocassionsStepSlice.actions;

export default ocassionsStepSlice.reducer;