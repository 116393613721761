import { api } from 'app/services'

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({

        login: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/auth/login`,
                    method: 'POST',
                    body,
                }
            },
        }),
        
        resetPassword: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/auth/reset-password`,
                    method: 'PATCH',
                    body,
                }
            },
        }),
        
        changePassword: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/auth/change-password`,
                    method: 'PATCH',
                    body,
                }
            },
        }),
        
        resendLink: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/auth/send-otp/${id}`,
                    method: 'GET',
                }
            },
        }),
        
        profile: builder.query({
            query: (id) => {
                return {
                    url: `/api/user/profile`,
                    method: 'GET',
                }
            },
        }),
        
        getProfile: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/user/profile`,
                    method: 'GET',
                }
            },
        }),

        sendOtp: builder.mutation({
            query: (id) => {
                return {
                    url: `/api/auth/send-otp/${id}`,
                }
            },
        }),
        forgotPassword: builder.mutation({
            query: (email) => {
                return {
                    url: `/api/auth/forgot-password/${email}`,
                }
            },
        }),
    }),
})

export const {
    useLoginMutation,
    useResetPasswordMutation,
    useChangePasswordMutation,
    useResendLinkMutation,
    useProfileQuery,
    useGetProfileMutation,
    useSendOtpMutation,
    useForgotPasswordMutation,
} = authApi