import { api } from 'app/services'

export const artistInvitationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        
        musicTrait: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/music-trait`,
                    method: 'PATCH',
                    body,
                }
            },
            // invalidatesTags: ['Shared']
        }),
        
        completeArtistProfile: builder.mutation({
            query: (body) => {
                return {
                    url: `/api/user/artist-details`,
                    method: 'POST',
                    body,
                }
            },
        }),

    }),
})

export const {
    useMusicTraitMutation,
    useCompleteArtistProfileMutation
} = artistInvitationApi